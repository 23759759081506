import React from "react";
import SEO from "../components/seo"
import "animate.css";

import Header from "./Header";
import Footer from "./Footer";

// const title = "Music Republicain"
const routes = [
  {
    title: "Music School",
    path: "/music-school/"
  },
  {
    title: "Online Program",
    path: "/online-program/"
  },
  {
    title: "Music Teachers",
    path: "/music-teachers/"
  },
  {
    title: "Concert Hall",
    path: "/concert-hall/"
  },
  {
    title: "Concerts & Performances",
    path: "/concerts-performances/"
  },
  {
    title: "Image Consulting",
    href: "https://www.ivanaatmojo.com/"
  },
  {
    title: "About Us",
    path: "/about-us/"
  },
  {
    title: "Contact Us",
    path: "/contact-us/"
  }
];

const Page = ({ children, title, description, className }) => (
  <>
    <SEO title={title} description={description ? description : "Music lesson, chamber group, band session, choir, early childhood music class, digital music course, etc."} />
    <Header title={title} routes={routes} />
    <div className={"min-vh-100 " + className}>{children}</div>
    <Footer />
  </>
);

export default Page;