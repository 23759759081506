import React, { useEffect } from "react";
import { Link } from "gatsby";
import { Navbar } from "react-bootstrap";
import styled from "@emotion/styled";
import $ from "jquery";

const NavLink = styled.li`
  ${props =>
    props.active &&
    `
      .nav-link--active {
        color: #cde7f6 !important;
        &:before {
          width: 80% !important;
          max-width: 
        }
      }
    `}
`;

export default ({ routes, title }) => {
  useEffect(() => {
    $(window).scroll(function () {
      var window_top = $(window).scrollTop() + 1;
      if (window_top > 50) {
        $(".main_menu")
          .addClass("menu_fixed animated fadeInDown")
          .removeClass("menu_top");
      } else {
        $(".main_menu")
          .removeClass("menu_fixed animated fadeInDown")
          .addClass("menu_top");
      }
    });
  });

  return (
    <div className="main_menu home_menu menu_top">
      <div className="container-xl">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <Navbar variant="light" expand="lg" id="site-navbar">
              <Navbar.Brand as="span">
                <div className="brand">
                  <Link to="/">Music Republicain</Link>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                className="collapse navbar-collapse main-menu-item animated fadeIn"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav align-items-center">
                  {routes.map((route, i) => (
                    <NavLink
                      key={i}
                      className={route.subpage ? "nav-item dropdown" : "nav-item"}
                      active={route.title === title}
                    >
                      {route.href ? (
                        <a
                          className="nav-link nav-link--active"
                          href={route.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {route.title}
                        </a>
                      ) : (
                          <>
                            <Link
                              className={route.subpage ? "nav-link nav-link--active dropdown-toggle" : "nav-link nav-link--active"}
                              to={!route.subpage && route.path}
                            >
                              {route.title}
                            </Link>
                            {route.subpage &&
                              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                {route.subpage.map((route_subpage, i) => (
                                  <Link
                                    className="dropdown-item"
                                    to={route_subpage.path}
                                    key={i}
                                  >
                                    {route_subpage.title}
                                  </Link>
                                ))}
                              </div>
                            }
                          </>
                        )}
                    </NavLink>
                  ))}
                </ul>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </div>
    </div>
  );
};
