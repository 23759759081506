import styled from "@emotion/styled"

import icInstagram from "../assets/icons/instagram.svg"
import icFacebook from "../assets/icons/facebook.svg"
import icYoutube from "../assets/icons/youtube.svg"

export default styled.i`
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  ${props => props.instagram && `background-image: url(${icInstagram});`};
  ${props => props.facebook && `background-image: url(${icFacebook});`};
  ${props => props.youtube && `background-image: url(${icYoutube});`};
`
