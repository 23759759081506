import React, { Component } from "react"
import Icon from "../components/IconSocmed"

class Footer extends Component {
  render() {
    return (
      <footer className="footer-area">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-4 col-sm-6 col-md-6">
              <div className="single-footer-widget footer_2">
                <div className="single_contact_info">
                  <h3 className="brand">MUSIC RÉPUBLICAIN</h3>
                  <p>
                    Jl. RS Fatmawati no 8, Dapur Susu, <br />
                    Pondok Labu, Jakarta Selatan 12450 <br />
                    info@music-republic.com | www.music-republic.com <br />
                    (021)751-1374 | <a href="https://wa.me/6281389113119">081-389-113-119</a>
                  </p>
                  <div className="social_icon">
                    <a href="https://www.instagram.com/musicrepublicain/">
                      <Icon instagram />
                    </a>
                    <a href="https://id-id.facebook.com/musicrepublicain/">
                      <Icon facebook />
                    </a>
                    <a href="https://www.youtube.com/channel/UCb2Mj4Qff7BPkjZYhIZoz2Q/featured">
                      <Icon youtube />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
